<template>
  <div>
    RoomsGroups
  </div>
</template>

<script>

export default {
  name: 'RoomsGroups',
  props: {},
};
</script>
